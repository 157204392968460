<template>
  <div class="welcome-main">
    <div class="w-100">
      <div class="text-center">
        <h1 class="display-3">Welcome</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  computed: {
    logoImage() {
      return this.$store.state.theme.Logo;
    },
  },
  methods: {
    testAlert() {
      this.$EventBus.$emit("showLoading");
    },
  },
};
</script>
<style scoped>
.welcome-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
